import "./index.css";

export type OneBoardOptions = {
  appPublicKey: string;
  verificationToken?: string;
  keepAuth?: boolean;
  url?: string;
};

export type OpenOnBoardingModalOptions = {
  fullScreen?: boolean;
};

class OneBoard {
  options: OneBoardOptions;
  constructor(options: OneBoardOptions) {
    this.options = options;
    if (!this.options.url) {
      this.options.url = "https://widget-testing.oneboard.group";
    }
  }

  private getUrl = () => {
    const { appPublicKey, verificationToken, keepAuth, url } = this.options;

    const params = [];

    if (appPublicKey) {
      params.push(`appPublicKey=${appPublicKey}`);
    }
    if (verificationToken) {
      params.push(`verificationToken=${verificationToken}`);
    }
    if (keepAuth) {
      params.push("keepAuth=true");
    }

    return `${url}/?${params.join("&")}`;
  };

  private getIframe = (): HTMLIFrameElement => {
    const iframe = document.createElement("iframe");
    iframe.classList.add("ob-main-frame");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("marginheight", "0");
    iframe.setAttribute("marginwidth", "0");
    iframe.setAttribute("width", "100%");
    iframe.setAttribute("height", "100%");
    iframe.setAttribute("scrolling", "auto");
    iframe.setAttribute("allow", "camera; microphone");
    iframe.setAttribute(
      "style",
      "border: none; width: 100%; min-height: 780px;"
    );

    iframe.setAttribute("src", this.getUrl());
    return iframe;
  };

  openOnBoardingModal = (options: OpenOnBoardingModalOptions = {}): void => {
    const { fullScreen = false } = options;

    const modal = document.createElement("div");
    modal.classList.add("ob-modal");

    if (fullScreen) {
      modal.classList.add("ob-modal--fullscreen");
    }

    const modalOverlay = document.createElement("div");
    modalOverlay.classList.add("ob-modal-overlay");
    modal.appendChild(modalOverlay);

    const modalBody = document.createElement("div");
    modalBody.classList.add("ob-modal-body");
    modal.appendChild(modalBody);

    const iframe = this.getIframe();
    modalBody.appendChild(iframe);

    const modalClose = document.createElement("div");
    modalClose.classList.add("ob-modal-close");
    modalBody.appendChild(modalClose);
    modalClose.addEventListener("click", () => {
      modal.remove();
    });

    document.body.appendChild(modal);
  };

  renderOnBoardingView = (container: HTMLElement | string): void => {
    let element: HTMLElement;

    if (typeof container === "string") {
      element = document.querySelector(container);
    } else if (container instanceof HTMLElement) {
      element = container;
    }

    if (!element) {
      return;
    }

    const iframe = this.getIframe();

    element.innerHTML = "";
    element.appendChild(iframe);
  };
}

export default OneBoard;
